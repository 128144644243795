export const actionTypes = {
  SET_RUN: 'SET_RUN',
  SET_STEP_INDEX: 'SET_STEP_INDEX',
};

const initialState = {
  run: false,
  stepIndex: 0,
};

export default function filtersTourReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_RUN:
      return { ...state, run: action.payload };
    case actionTypes.SET_STEP_INDEX:
      return { ...state, stepIndex: action.payload };
    default:
      return state;
  }
}
