import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { getFooterNextButtonText, setFiltersTourPersistentState } from '../Helpers';
import { getListUrlFromPath, paths } from '../../../routes/constants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { SkipLink } from '../../Joyride/components/commonProps/TooltipComponent';
import { useDispatch } from 'react-redux';
import { setFilterTourStepIndex, setFiltersTourRun } from '../../../redux/filtersTour/actions';
import { sleep } from '../../../helpers/other';
import { ChevronRight } from 'react-feather';

const CustomTooltipFooterNavigation = ({ skipProps, primaryProps, index, size }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const nextButtonRef = useRef(null);
  //eslint-disable-next-line
  const [disabled, setDisabled] = useState(false);
  const next = () => dispatch(setFilterTourStepIndex(index + 1));

  useEffect(() => {
    if (index + 1 === size) {
      setFiltersTourPersistentState({
        status: 'done',
      });
    }
  }, [index, primaryProps, size]);

  return (
    <Box p={4} display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center" gap={2}>
        <SkipLink
          {...skipProps}
          onClick={(e) => {
            skipProps.onClick(e);
            setFiltersTourPersistentState({ status: 'done' });
            dispatch(setFiltersTourRun(false));
          }}
        >
          {'Skip Tips'}
        </SkipLink>
        <Button
          ref={nextButtonRef}
          {...primaryProps}
          onClick={async () => {
            const currentPathname = window.location.pathname;
            const manageOrdersPath = getListUrlFromPath(paths.FULFILLMENT_ORDERS);
            if (currentPathname !== manageOrdersPath) {
              history.push(manageOrdersPath);

              next();

              dispatch(setFiltersTourRun(false));

              await sleep(1000);

              dispatch(setFiltersTourRun(true));
              return;
            }

            if (index === 1) {
              const dateFilter = document.querySelector('#date-filter-trigger');
              dateFilter.click();

              setTimeout(() => next(), 200);
              return;
            }

            if (index === 2) {
              const customDateFilterTrigger = document.querySelector('[id="Custom"]');

              customDateFilterTrigger.click();

              setTimeout(() => next(), 500);
              return;
            }

            if (index === 5) {
              const tableFilterButton = document.querySelector('#custom-datatable-filter').firstChild;
              tableFilterButton.click();

              setTimeout(() => next(), 200);

              return;
            }

            if (index === 7) {
              return dispatch(setFiltersTourRun(false));
            }

            next();
          }}
          disabled={disabled}
          color={index === 0 ? 'secondary' : 'primary'}
          variant="contained"
          size="small"
          endIcon={getFooterNextButtonText(index) === 'Next' && <ChevronRight />}
        >
          {getFooterNextButtonText(index)}
        </Button>
      </Box>
      <Typography>
        {index + 1} of {size}
      </Typography>
    </Box>
  );
};

export default CustomTooltipFooterNavigation;
