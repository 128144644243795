import { actionTypes } from './reducer';

export const setFiltersTourRun = (run) => ({
  type: actionTypes.SET_RUN,
  payload: run,
});
export const setFilterTourStepIndex = (index) => ({
  type: actionTypes.SET_STEP_INDEX,
  payload: index,
});
