import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Power } from 'react-feather';
import '../vendor/perfect-scrollbar.css';
import Sidebar, { SidebarOverlay } from '../Components/Sidebar';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import ErrorBoundary from '../Components/ErrorBoundary';
import { Box, CssBaseline, Tooltip, Typography, IconButton as MuiIconButton } from '@mui/material';
import { KeyboardArrowRight, FiberManualRecord as BulletIcon, Clear } from '@mui/icons-material';
import { NavLink as RouterNavLink } from 'react-router-dom';
import AppSnackbar from '../Components/AppSnackbar';
import AppConfirmationDialog from '../Components/AppConfirmationDialog';
import FrontDeskWidgetAction from '../Components/FrontDeskWidgetAction';
import FiltersTour from '../Components/FiltersTour/Views';
import { getListUrlFromPath, paths } from '../routes/constants';
import { logout } from '../redux/auth/actions';
import { getSidebarRoutes } from '../routes';
import SidebarCategory from './SidebarCategory';
import styled, { withTheme } from 'styled-components';
import Tour from '../Components/Joyride';
import WelcomeMessage from '../Components/Joyride/components/Onboarding/views/WelcomeMessage';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import {
  ChildLinkText,
  LinkBadge,
  BulletStyle,
  Root,
  GlobalStyle,
  Drawer,
  ClosedDrawer,
  SimpleLink,
  arrowIconRight,
  AppContent,
  MainContent,
  SidebarFooter,
  IconButton,
  drawerWidth,
  CollapsableChildLink,
  CollapsableChildren,
  Category,
  BoxHead,
  IconsWrapper,
  closeDrawerWidth,
} from './dashboardcss';
import { useLocalStorageListner } from '../hooks/useLocalStorageListner';
import { LS_OMS_CURRENT_ACTIVE_BRAND } from '../Auth/Constants/constants';
import useScreenSize from '../hooks/useScreenSize';
import { useMediaQuery } from '@mui/material';

const NavLink = React.forwardRef((props, ref) => <RouterNavLink innerRef={ref} {...props} />);

function CollapsableSidebarLink({ name, to, badge }) {
  return (
    <CollapsableChildLink button dense component={NavLink} exact to={to} activeclassname="active">
      <BulletIcon style={BulletStyle} />
      <ChildLinkText>{name}</ChildLinkText>
      {badge ? <LinkBadge label={badge} /> : ''}
    </CollapsableChildLink>
  );
}
const Dashboard = ({ children, location, theme }) => {
  const { run: isFiltersTourRunning } = useSelector((state) => state.filtersTour);

  const brandChangeListnerHandler = useCallback(() => {
    window.location.reload();
  }, []);
  const { width: innerWidth } = useScreenSize();
  const isLargeUp = useMediaQuery(theme.breakpoints.up('lg'));
  useLocalStorageListner(LS_OMS_CURRENT_ACTIVE_BRAND, brandChangeListnerHandler);
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isSideNavOpen, setIsSideNavOpen] = useState(true);
  const [showBanner, setShowBanner] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const activeTab = useSelector((state) => state.sidebar.activeTab);
  let routes = getSidebarRoutes(user.dashboard_version, user, activeTab);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    setIsSideNavOpen(true);
  };

  const initOpenRoutes = (routes = []) => {
    /* Open collapse element that matches current url */
    const pathName = location?.pathname;
    let _routes = {};
    routes.forEach((route, index) => {
      const isactive = pathName?.indexOf(route.path) === 0;
      const isopen = route?.open;
      const ishome = route?.containsHome && pathName === paths.INDEX ? true : false;

      _routes = Object.assign({}, _routes, {
        [index]: isactive || isopen || ishome,
      });
    });

    return _routes;
  };
  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(routes));

  useEffect(() => {
    const data = window.localStorage.getItem('IS_SIDENAV_OPEN');
    if (data !== null) setIsSideNavOpen(JSON.parse(data));
  }, []);
  useEffect(() => {
    window.localStorage.setItem('IS_SIDENAV_OPEN', JSON.stringify(isSideNavOpen));
  }, [isSideNavOpen]);
  useEffect(() => {
    const close = (e) => {
      e.shiftKey && e.keyCode === 37 && setIsSideNavOpen(false);
    };
    const open = (e) => {
      e.shiftKey && e.keyCode === 39 && setIsSideNavOpen(true);
    };
    window.addEventListener('keydown', close);
    window.addEventListener('keydown', open);
    return () => {
      window.removeEventListener('keydown', close);
      window.removeEventListener('keydown', open);
    };
  }, []);

  // Remove it later on this exists only to
  // clear banner dismissed state in localStorage
  useEffect(() => {
    if (localStorage.getItem('bannerDismissed-shopee9Nov')) {
      localStorage.removeItem('bannerDismissed-shopee9Nov');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeBanner = () => {
    setShowBanner(false);
    localStorage.setItem('bannerDismissed-shopee9Nov', true);
  };

  const openChildBox = (index) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) => openRoutes[index] || setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [item]: false }))
    );
    // Toggle selected element
    setOpenRoutes((openRoutes) => Object.assign({}, openRoutes, { [index]: true }));
  };
  const childTooltipMessage = (category, index) => {
    return (
      <CollapsableChildren in={openRoutes[index]}>
        <Box style={{ padding: '0 0.6rem' }}>
          <BoxHead variant="body2">{category?.id}</BoxHead>
          <hr style={{ marginLeft: '-.5rem', marginRight: '-.5rem' }}></hr>
          {category?.children?.map((route) => (
            <>
              <CollapsableSidebarLink key={category?.id} name={route?.name} to={getListUrlFromPath(route?.path)} />
            </>
          ))}
        </Box>
      </CollapsableChildren>
    );
  };

  const isMdDown = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  return (
    <Root>
      <FrontDeskWidgetAction type="show" />
      <CssBaseline />
      <GlobalStyle />
      <FiltersTour />
      {isSideNavOpen ? (
        <>
          <div style={{ position: 'relative' }} direction="column">
            <Drawer className="sidebar">
              {isMdDown && (
                <Sidebar
                  PaperProps={{
                    style: {
                      width: drawerWidth,
                      overflow: 'hidden',
                      background: theme?.sidebar?.background || '#060A06',
                      ...(isFiltersTourRunning && {
                        zIndex: 0,
                        position: 'relative',
                      }),
                    },
                  }}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  isSideNavOpen={isSideNavOpen}
                  setIsSideNavOpen={setIsSideNavOpen}
                  tourNotSupported={true}
                />
              )}
              {isMdUp && (
                <>
                  <Tour {...{ routes }} />
                  <Sidebar
                    PaperProps={{
                      style: {
                        width: drawerWidth,
                        overflow: 'hidden',
                        background: theme?.sidebar?.background || '#060A06',
                        ...(isFiltersTourRunning && {
                          zIndex: 0,
                          position: 'relative',
                        }),
                      },
                    }}
                    isSideNavOpen={isSideNavOpen}
                    setIsSideNavOpen={setIsSideNavOpen}
                  />
                </>
              )}
            </Drawer>
            <WelcomeMessage {...{ routes }} />
          </div>
        </>
      ) : (
        <ClosedDrawer>
          {isFiltersTourRunning && <SidebarOverlay />}
          {isMdUp && !isFiltersTourRunning && (
            <>
              <Box style={{ marginBottom: '.4rem' }}>
                <SimpleLink
                  component={NavLink}
                  to={paths.INDEX}
                  exact
                  style={{ height: '4rem', justifyContent: 'center' }}
                >
                  <Box pt={2} component="span">
                    <img alt="logo" src={theme.body.logos.darkMini} style={{ width: '2.5rem' }} />
                  </Box>
                </SimpleLink>
                <Box
                  style={arrowIconRight}
                  className="on-hover-border"
                  onClick={() => setIsSideNavOpen(!isSideNavOpen)}
                >
                  <KeyboardArrowRight />
                </Box>
              </Box>
              <IconsWrapper className="icons-wrapper-box-sidebar">
                {routes?.map((category, index) =>
                  category?.children ? (
                    <Tooltip
                      enterTouchDelay={0}
                      placement="right"
                      interactive
                      title={childTooltipMessage(category, index)}
                      key={index}
                    >
                      <Category onMouseEnter={() => openChildBox(index)}>
                        <Box
                          activeclassname="active"
                          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                          {category?.icon}
                        </Box>
                      </Category>
                    </Tooltip>
                  ) : (
                    <SidebarCategory
                      name={category?.id}
                      to={getListUrlFromPath(category?.path)}
                      exact
                      activeclassname="active"
                      component={NavLink}
                      icon={category?.icon}
                      key={index}
                    />
                  )
                )}
              </IconsWrapper>
              <SidebarFooter>
                <IconButton id="logout" onClick={() => dispatch(logout())}>
                  <Power />
                </IconButton>
              </SidebarFooter>
            </>
          )}
        </ClosedDrawer>
      )}
      <AppContent>
        {showBanner && (
          <Box
            style={{
              backgroundColor: 'var(--colors-secondary)',
              wordBreak: 'break-word',
              padding: '4px 10px',
            }}
            display={'flex'}
          >
            <ErrorOutlineIcon />
            <Typography style={{ margin: '0px 8px' }}>
              <b>Attention / Please Note (Updated as on 24th July 2024): </b>Due to heavy rains and typhoon advisory in
              the Philippines, our warehouses - Binan LTI, Asinan, and Southgate are operating at limited capacity.
              Metro Manila, Cavite, Laguna, Batangas Pampanga and Rizal deliveries are expected to have 2-3 days of
              delay. Other areas are expected to have 5-7 days of delay.
            </Typography>

            <MuiIconButton aria-label="close banner" component="span" onClick={closeBanner}>
              <Clear fontSize="16px" />
            </MuiIconButton>
          </Box>
        )}
        <Header onDrawerToggle={handleDrawerToggle} />
        <MainContent
          p={isLargeUp ? 6 : 5}
          innerWidth={innerWidth}
          isSideNavOpen={isSideNavOpen}
          pt={4}
          style={{ marginLeft: !isSideNavOpen && innerWidth > 960 ? `${closeDrawerWidth}rem` : null }}
        >
          <ErrorBoundary>{children}</ErrorBoundary>
        </MainContent>
        <FooterWrapper>
          <Footer user={user} routes={routes} isSideNavOpen={isSideNavOpen} />
        </FooterWrapper>
      </AppContent>
      <AppSnackbar />
      <AppConfirmationDialog />
    </Root>
  );
};

export default withTheme(Dashboard);
const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 25px;
  overflow: hidden;
`;
