import { FILTERS_TOUR } from '../Constants';

export const getFooterNextButtonText = (index) => {
  switch (index) {
    case 0:
      return 'Start Tour';
    case 7:
      return 'Done';
    default:
      return 'Next';
  }
};
export const setFiltersTourPersistentState = (newState) => {
  const filtersTour = JSON.parse(localStorage.getItem(FILTERS_TOUR)) || {};

  localStorage.setItem(
    FILTERS_TOUR,
    JSON.stringify({
      ...filtersTour,
      ...newState,
    })
  );
};
export const getFiltersTourPersistentState = () => JSON.parse(localStorage.getItem(FILTERS_TOUR));
