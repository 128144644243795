import { Box, Typography } from '@mui/material';
import React from 'react';

export const FILTERS_TOUR = 'FILTERS_TOUR';
const ContentWrapper = ({ title, children }) => {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography fontWeight="bold" fontSize={16}>
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export const steps = [
  {
    disableBeacon: true,
    content: (
      <ContentWrapper title="A New Filter Experience">
        <Typography>Improved readability, easier navigation, and better results</Typography>
        <Typography>with our updated filter functionality.</Typography>
      </ContentWrapper>
    ),
    placement: 'center',
    target: 'body',
  },
  {
    target: '#global-date-filter',
    content: (
      <ContentWrapper title="New and Improved Date Filter">
        <Typography>
          Our new and updated global Date Filter allows you to pick date and time ranges easily now. Click next to see
          the updated Date filter.{' '}
        </Typography>
      </ContentWrapper>
    ),
  },
  {
    target: '#date-filter-options',
    placement: 'left',
    content: (
      <ContentWrapper title="Date Filter">
        <Typography>Select a preset or configure a custom date range.</Typography>
      </ContentWrapper>
    ),
  },
  {
    target: '#custom-date-filter-tour',
    placement: 'left',
    content: (
      <ContentWrapper title="Date Filter">
        <Typography>Clicking {`"Custom Date"`} will lead you to a date picker where</Typography>
        <Typography>you can select a date and time range.</Typography>
      </ContentWrapper>
    ),
  },
  {
    target: '#marketplace-tab-filters',
    placement: 'bottom-start',
    content: <ContentWrapper title="Tab Filters">Quick tabs for improved readability across all pages.</ContentWrapper>,
  },
  {
    target: '#custom-datatable-filter',
    placement: 'left',
    content: (
      <ContentWrapper title="Table Functionalities">
        <Typography>
          Table Filters can be found at the top right of the Table Views. Click next to see the updated Table filter
        </Typography>
        <Typography>Button</Typography>
      </ContentWrapper>
    ),
  },
  {
    target: '#custom-filters',
    placement: 'left',
    content: (
      <ContentWrapper title="Table Filters">
        <Typography>Active filters are now indicated by a number and can be</Typography>
        <Typography>found inside the “Select Filters” section. Active filters can</Typography>
        <Typography>be easily removed by deselecting Filter pills. </Typography>
      </ContentWrapper>
    ),
  },
  {
    target: '#global-date-filter',
    content: (
      <ContentWrapper title="Try it out!">
        <Typography>Discover how our enhanced filter system makes finding</Typography>
        <Typography>what you need easier and more efficient</Typography>
      </ContentWrapper>
    ),
  },
];
