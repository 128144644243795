import React, { useEffect } from 'react';
import Joyride from 'react-joyride';
import { FILTERS_TOUR, steps } from '../Constants';
import { useSelector } from 'react-redux';
import CustomTooltipFooterNavigation from '../Components/CustomTooltipFooterNavigation';
import { setFiltersTourPersistentState } from '../Helpers';
import { TooltipComponent } from '../../Joyride/components/commonProps/TooltipComponent';
import { useDispatch } from 'react-redux';
import { setFilterTourStepIndex, setFiltersTourRun } from '../../../redux/filtersTour/actions';
import { setGlobalDateFilter } from '../../../redux/auth/actions';
import { getDefaultDateRangeFilters } from '../../../helpers/datetime';

const FiltersTour = ({ setRun }) => {
  const dispatch = useDispatch();
  const { run, stepIndex } = useSelector((state) => state.filtersTour);
  const status = JSON.parse(localStorage.getItem(FILTERS_TOUR))?.status || null;

  useEffect(() => {
    if (status === 'done') return;
    if (stepIndex > 1) {
      dispatch(setFilterTourStepIndex(0));
    }
    setTimeout(() => {
      //set global date filter to this so filters will be present
      dispatch(setGlobalDateFilter(getDefaultDateRangeFilters().find((filter) => filter.value === 'This Month')));
      dispatch(setFiltersTourRun(true));
      setFiltersTourPersistentState({
        status: 'running',
      });
    }, 3000);

    //eslint-disable-next-line
  }, [dispatch, status]);

  return (
    <Joyride
      stepIndex={stepIndex}
      continuous
      run={run}
      disableCloseOnEsc
      disableOverlayClose
      steps={steps}
      tooltipComponent={(args) => (
        <TooltipComponent
          {...args}
          customFooterNavigation={
            <CustomTooltipFooterNavigation
              skipProps={args.skipProps}
              setRun={setRun}
              size={args.size}
              index={args.index}
              primaryProps={args.primaryProps}
            />
          }
        />
      )}
    />
  );
};

export default FiltersTour;
