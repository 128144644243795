import * as Yup from 'yup';

export const LoginFailMessaages = {
  whenStateDoesntMatch: 'Data Mismatch Detected. Please Log In Again.',
  whenCodeValidationFails: 'Error Occurred. Please Try Logging In Again.',
};

export const LS_OMS_CURRENT_ACTIVE_BRAND = 'OMS_CURRENT_ACTIVE_BRAND';

export const STORED_CREDENTIALS = 'STORED_CREDENTIALS';
export const EMAIL_INVITE_LOGIN_HEADER_TEXT = (brandId) => `Sign in to link your account to ${brandId}`;
export const EMAIL_INVITE_SIGNUP_HEADER_TEXT = (brandId) => `You have been invited to join ${brandId}!`;
export const DEFAULT_LOGIN_HEADER_TEXT = 'Welcome back! Please enter your details.';
export const EMAIL = 'email';
export const BRAND_NAME = 'brand_name';
export const NEW_USER = 'newuser';
export const FORCE_LOGIN_FLAG = 'force_login';

export const LOCAL_STORAGE_EMAIL = 'Invited Email';
export const LOCAL_STORAGE_BRAND_NAME = 'Invited Brand';

export const SUB_HEADER_TEXT = (credit) =>
  `Sign up for free to start shipping today. New account will receive USD ${credit} trial credits.`;

export const HEADER_TEXT_MULTIPLE_BRAND = (username) =>
  `Welcome ${username}, Choose the account you want to link to Shopify:`;
export const HEADER_TEXT_SINGLE_BRAND = (username, brand, shopifyBrand) =>
  `Welcome ${username}, link ${brand} to your Shopify: ${shopifyBrand}`;

export const SHOPIFY_EXISTING_USER_SUB_HEADER_TEXT = `We will contact you within 2 business days to guide you through the onboarding process.`;

export const SHOPIFY_SIGNUP_MAP_LOCATION_SUB_HEADER_TEXT = `Tell us what to call your Brand`;

export const SUB_HEADER_TEXT_SINGLE_BRAND = `Orders from the last 7 days will be automatically synced from your Shopify store to \n your Locad dashboard.`;

export const MAP_LOCATION_DEFAULT = (username) =>
  `Welcome, ${username}. Add your first pickup location to start shipping!`;
export const FILL_DETAILS_TEXT = `Fill in details for your first pickup location. Your
  location settings will not be modified.`;

export const WAREHOUSE_SELECT_DETAILS = `Choose an existing warehouse from the list`;

export const SHOPIFY_PUBLIC_APP_URL = 'https://apps.shopify.com/fulfilment-by-locad';

export const RedirectHubspotUser = (page) => {
  switch (page) {
    case 'shopify':
      return SHOPIFY_PUBLIC_APP_URL;
    case 'locad':
      return 'https://golocad.com/';
    default:
      break;
  }
};

export const PERMISSIONS = {
  BILLING_RATE_EDIT: 'BILLING_RATE_EDIT',
  BILLING_MODULE_VIEW: 'BILLING_MODULE_VIEW',
  BILLING_REPORT_GENERATE: 'BILLING_REPORT_GENERATE',
  ORDER_SHIPPING_ADDRESS_MAPPED_COMP_VIEW: 'ORDER_SHIPPING_ADDRESS_MAPPED_COMP_VIEW',
  ORDER_SHIPPING_ADDRESS_MAPPED_COMP_UPDATE: 'ORDER_SHIPPING_ADDRESS_MAPPED_COMP_UPDATE',
  EDIT_USERMANAGEMENT: 'EDIT_USERMANAGEMENT',
  VIEW_USERMANAGEMENT: 'VIEW_USERMANAGEMENT',
  EDIT_INVITEUSER: 'EDIT_INVITEUSER',
  VIEW_INVITEUSER: 'VIEW_INVITEUSER',
  EDIT_PENDING_INVITES: 'EDIT_PENDING_INVITES',
  VIEW_PENDING_INVITES: 'VIEW_PENDING_INVITES',
  RUN_BILLS_FROM_UI: 'RUN_BILLS_FROM_UI',
  VIEW_CARRIER_MANAGEMENT: 'VIEW_CARRIER_MANAGEMENT',
  EDIT_CARRIER_MANAGEMENT: 'EDIT_CARRIER_MANAGEMENT',
  FULFILMENT_ORDER_RE_SHIP: 'FULFILMENT_ORDER_RE_SHIP',
  EDIT_NOTIFICATION_SETTINGS: 'EDIT_NOTIFICATION_SETTINGS',
  EDIT_BRAND_NOTIFICATION_PREFS: 'EDIT_BRAND_NOTIFICATION_PREFS',
  EDIT_INVENTORY_PRODUCT: 'EDIT_INVENTORY_PRODUCT',
  EDIT_PRODUCT_BUFFER_PERCENTAGE: 'EDIT_PRODUCT_BUFFER_PERCENTAGE',
  EDIT_EXPRESS_RATE_NAME: 'EDIT_EXPRESS_RATE_NAME',
  VIEW_EXPRESS_RATE_NAME: 'VIEW_EXPRESS_RATE_NAME',
  MARKETPLACE_AUTHORIZE: 'MARKETPLACE_AUTHORIZE',
  MARKETPLACE_REAUTHORIZE: 'MARKETPLACE_REAUTHORIZE',
  VIEW_INVOICE_DETAILS: 'VIEW_INVOICE_DETAILS',
  EDIT_BILLING_ACCOUNT_MAPPING: 'EDIT_BILLING_ACCOUNT_MAPPING',
  BULK_WH_TRANSFER_CREATE_UPDATE: 'BULK_WH_TRANSFER_CREATE_UPDATE',
  VIEW_BRAND_WAREHOUSE_CONTRACTS: 'VIEW_BRAND_WAREHOUSE_CONTRACTS',
  VIEW_BILLING_ACCOUNT_MAPPING: 'VIEW_BILLING_ACCOUNT_MAPPING',
  MANUALLY_RELEASE_FULFILMENT_ORDER: 'MANUALLY_RELEASE_FULFILMENT_ORDER',
  ALLOW_FO_RECREATION: 'ALLOW_FO_RECREATION',
  MARKETPLACE_SELLER_HEALTH_VIEW: 'MARKETPLACE_SELLER_HEALTH_VIEW',
  VIEW_SHIPMENT_BILLING: 'VIEW_SHIPMENT_BILLING',
  USE_ALL_SHIPMENT_TYPES: 'USE_ALL_SHIPMENT_TYPES',
  OUTBOUND_BULK_ORDER_CANCEL: 'OUTBOUND_BULK_ORDER_CANCEL',
  OUTBOUND_BULK_ORDER_DELETE: 'OUTBOUND_BULK_ORDER_DELETE',
  LOCAD_STAFF: 'LOCAD_STAFF',
  FEATURE_PRESERVE_TABLE_FILTERS: 'FEATURE_PRESERVE_TABLE_FILTERS',
  VIEW_ORDER_UPLOADS: 'VIEW_ORDER_UPLOADS',
  VIEW_RETURNS: 'VIEW_RETURNS',
  CREATE_RETURNS: 'CREATE_RETURNS',
  SCHEDULED_REPORT_VIEW_PERMISSION_MP: 'SCHEDULED_REPORT_VIEW_PERMISSION_MP',
  PRODUCT_STOCK_DETAIL_VIEW_MP: 'PRODUCT_STOCK_DETAIL_VIEW_MP',
  PRODUCT_SERIAL_DETAIL_VIEW_MP: 'PRODUCT_SERIAL_DETAIL_VIEW_MP',
  UPDATE_BRAND_FRESHDESK_COMPANY: 'UPDATE_BRAND_FRESHDESK_COMPANY',
  CREATE_FRESHDESK_COMPANY: 'CREATE_FRESHDESK_COMPANY',
  FEATURE_PRESERVE_TABLE_PAGINATION_MP: 'FEATURE_PRESERVE_TABLE_PAGINATION_MP',
  VIEW_CREDIT_LIMIT: 'VIEW_CREDIT_LIMIT',
  EDIT_CREDIT_LIMIT: 'EDIT_CREDIT_LIMIT',
  VIEW_METABASE_DASHBOARD: 'VIEW_METABASE_DASHBOARD',
  ALLOW_ORDER_EDIT: 'ALLOW_ORDER_EDIT',
  MARKETPLACE_UPD_ORD_SYNC: 'MARKETPLACE_UPD_ORD_SYNC',
  MARKETPLACE_UPD_CSK_SYNC: 'MARKETPLACE_UPD_CSK_SYNC',
  MARKETPLACE_CONNECTION_HISTORY: 'MARKETPLACE_CONNECTION_HISTORY',
  MARKETPLACE_UPD_OVR_SEL: 'MARKETPLACE_UPD_OVR_SEL',
  CATALOGUE_PRODUCT_UPDATE_INV_SYNC: 'CATALOGUE_PRODUCT_UPDATE_INV_SYNC',
  CATALOGUE_PRODUCT_INV_SYNC_HISTORY: 'CATALOGUE_PRODUCT_INV_SYNC_HISTORY',
  MANAGE_PRODUCT_PACK_HIERARCHY: 'MANAGE_PRODUCT_PACK_HIERARCHY',
  MARKETPLACE_PRODUCT_SYNC: 'MARKETPLACE_PRODUCT_SYNC',
  WAREHOUSE_PRODUCT_SYNC: 'WAREHOUSE_PRODUCT_SYNC',
  FULFILMENT_ORDER_RE_SHIP_BEFORE_PICKED: 'FULFILMENT_ORDER_RE_SHIP_BEFORE_PICKED',
};

const shipment_type_all = ['Standard', 'Express', 'SameDay', 'NextDay'];
const shipment_type = ['Standard', 'Express'];
export const getShipmentTypes = (permissions) =>
  permissions.includes(PERMISSIONS.USE_ALL_SHIPMENT_TYPES) ? shipment_type_all : shipment_type;

export const NoBrandAssigned = {
  header: 'Oops..',
  // body: `Your Locad account has not been configured correctly. To resolve this, please re-install the Shopify app, or reach out to Customer Success via the Support button.`,
  body: (email) =>
    `Your Locad account <em>${email}</em> has not been configured correctly. To resolve this reach out to Customer Success via the Support button.`,
};

export const TNCModalAction = {
  REJECT: 'REJECT',
  ACCEPT: 'ACCEPT',
};

export const MigrationNotice = {
  header: "We're migrating your account!",
  body: {
    part1: 'We are migrating our user management system to improve security.',
    part2: 'Your existing account will be automatically linked and all data will be safely retained.',
    // part3: 'Please click on the button below to proceed. To link your account, you will need to "Continue with Google," or "Sign up" with an email account'
  },
  actionText: 'Migrate now',
};

export const migratedUserWarning =
  'Your account has already been migrated. Please click SSO or Email login button to access dashboard';

let brandCreationvalidationMessage =
  "Please ensure brand name only contains alphanumeric characters, spaces, single quotes ('), plus (+), minus (-), forward slash (/), underscore (_), ampersand (&), and at (@) symbols.";

export const BrandCreationValidationSchema = Yup.object().shape({
  brand_name: Yup.string()
    .required('Brand name is required.')
    .matches("^[a-zA-Z0-9 '+-/_&@]*$", brandCreationvalidationMessage),
  default_currency: Yup.string().required('Select a currency.'),
});
